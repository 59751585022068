<template>
  <div class="el-collapse-box">
    <el-form
      :model="ruleForm"
      :rules="rules"
      inline
      ref="ruleForm"
      label-position="top"
      label-width="100px"
      class="el-collapse-box"
    >
      <AssociationScroll>
        <AssociationScrollView title="项目信息" name="0">
          <div class="container container-box">
            <el-form-item label="项目名称：" prop="projectName">
              <el-input v-model="ruleForm.projectName"></el-input>
            </el-form-item>
            <el-form-item label="项目类型：" prop="projectCategory">
              <el-select
                v-model="ruleForm.projectCategory"
                filterable
                @change="
                  changeSelect(
                    $event,
                    projectCategoryItems,
                    'projectCategoryValue',
                    ruleForm
                  )
                "
                placeholder="请选择"
              >
                <el-option
                  v-for="item in projectCategoryItems"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="项目性质：" prop="projectType">
              <el-select
                v-model="ruleForm.projectType"
                filterable
                @change="
                  changeSelect(
                    $event,
                    projectItems,
                    'projectTypeValue',
                    ruleForm
                  )
                "
                placeholder="请选择"
              >
                <el-option
                  v-for="item in projectItems"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品线：" prop="productType">
              <el-select
                v-model="ruleForm.productType"
                @change="
                  changeSelect(
                    $event,
                    projectLines,
                    'productTypeValue',
                    ruleForm
                  )
                "
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in projectLines"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="签约保费(元)：" prop="targetMoney">
              <el-input
                v-model="ruleForm.targetMoney"
                @input="
                  input_num($event, ruleForm, 'targetMoney', 99999999999999)
                "
              ></el-input>
            </el-form-item>
            <el-form-item label="签约赔付率：" prop="targetCompensate">
              <el-input
                v-model="ruleForm.targetCompensate"
                @input="input_num($event, ruleForm, 'targetCompensate', 100)"
                ><template slot="append">%</template></el-input
              >
            </el-form-item> -->
            <el-form-item label="项目负责人：" prop="projectLeaderId">
              <EmployeeSelect isChangeTenantId
                v-model="ruleForm.projectLeaderId" :placeholderName="ruleForm.projectLeader" 
                @change="handleChangeSalesman"
                placeholder="请选择"
              />
           </el-form-item>
             <el-form-item label="开始时间：" prop="startDateTime">
              <el-date-picker
                v-model="ruleForm.startDateTime"
                type="date"
                format="yyyy / MM / dd"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间：" prop="endDateTime">
              <el-date-picker
                v-model="ruleForm.endDateTime"
                type="date"
                format="yyyy / MM / dd"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="启用状态：" prop="enable">
              <el-radio-group v-model="ruleForm.enable">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="排序：" prop="order">
              <el-input v-model="ruleForm.order" @input="
                  input_num($event, ruleForm, 'order', 99999999999999, 0)
                "></el-input>
            </el-form-item>
            <!-- <el-form-item label="绩效类型：" prop="performanceTypeName">
              <el-select
                v-model="ruleForm.performanceTypeName"
                filterable
                placeholder="请选择" @change="
                  changeSelect(
                    $event,
                    performanceType,
                    'performanceType',
                    ruleForm
                  )"
              >
                <el-option
                  v-for="item in performanceType"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item> -->
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="销售指标" name="01">
          <div class="container container-box">
            <el-form-item label="签约保费(元)：" prop="signPremiums">
              <template slot="label">
                <div class="container-box-form-label">
                  <div>签约保费(元)：</div>
                  <textSwitch
                    active-text="隐藏"
                    :width="50"
                    v-model="ruleForm.signPremiumsDisplay"
                    inactive-text="显示"
                  />
                </div>
              </template>
              <el-input
                v-model="ruleForm.signPremiums"
                @input="
                  input_num($event, ruleForm, 'signPremiums', 99999999999999)
                "
              ></el-input>
            </el-form-item>
            <el-form-item label="签约赔付率：" prop="signCompensateRate">
              <template slot="label">
                <div class="container-box-form-label">
                  <div>签约赔付率：</div>
                  <textSwitch
                    active-text="隐藏"
                    :width="50"
                    v-model="ruleForm.signCompensateRateDisplay"
                    inactive-text="显示"
                  />
                </div>
              </template>
              <el-input
                v-model="ruleForm.signCompensateRate"
                @input="input_num($event, ruleForm, 'signCompensateRate', 100)"
                ><template slot="append">%</template></el-input
              >
            </el-form-item>
            <el-form-item label="目标赔付率：" prop="targetCompensate">
              <template slot="label">
                <div class="container-box-form-label">
                  <div>目标赔付率：</div>
                  <textSwitch
                    active-text="隐藏"
                    :width="50"
                    v-model="ruleForm.targetCompensateDisplay"
                    inactive-text="显示"
                  />
                </div>
              </template>
              <el-input
                v-model="ruleForm.targetCompensate"
                @input="input_num($event, ruleForm, 'targetCompensate', 100)"
                ><template slot="append">%</template></el-input
              >
            </el-form-item>
            <el-form-item label="签约管控率：" prop="signCtrlRate">
              <template slot="label">
                <div class="container-box-form-label">
                  <div>签约管控率：</div>
                  <textSwitch
                    active-text="隐藏"
                    :width="50"
                    v-model="ruleForm.signCtrlRateDisplay"
                    inactive-text="显示"
                  />
                </div>
              </template>
              <el-input
                v-model="ruleForm.signCtrlRate"
                @input="input_num($event, ruleForm, 'signCtrlRate', 100)"
                ><template slot="append">%</template></el-input
              >
            </el-form-item>
            <el-form-item label="目标管控率：" prop="targetCtrlRate">
              <template slot="label">
                <div class="container-box-form-label">
                  <div>目标管控率：</div>
                  <textSwitch
                    active-text="隐藏"
                    :width="50"
                    v-model="ruleForm.targetCtrlRateDisplay"
                    inactive-text="显示"
                  />
                </div>
              </template>
              <el-input
                v-model="ruleForm.targetCtrlRate"
                @input="input_num($event, ruleForm, 'targetCtrlRate', 100)"
                ><template slot="append">%</template></el-input
              >
            </el-form-item>
          </div>
        </AssociationScrollView>
        <!-- <AssociationScrollView title="项目文件" name="1">
          <div class="container container-box" v-loading="fileloading">
            <FormListUpload
              @allSuccess="handleAllFileSuccess"
              @delItem="handleDelItem"
              required
              :limit="10"
              :limitType="[
                'doc',
                'docx',
                'pdf',
                'xlsx',
                'pptx',
                'jpg',
                'png',
                'jpeg',
              ]"
              tips="只支持doc,docx,pdf,xlsx,jpg,jpeg,png,pptx格式，单个文件不超过2MB，最多上传10个文件"
              title=""
              v-model="ruleForm.fileList"
            ></FormListUpload>
          </div>
        </AssociationScrollView> -->
        <!-- <AssociationScrollView title="项目目标" name="2">
          <div class="container container-box">
            <TargetTabs v-model="ruleForm.targetInfo"></TargetTabs>
          </div>
        </AssociationScrollView> -->
        <!-- <AssociationScrollView title="项目客户" name="2">
          <div class="container container-box">
            <div>
              <el-button
                type="primary"
                icon="el-icon-refresh"
                size="small"
                plain
                @click="$refs.customer.refresh()"
              ></el-button>
              <el-button
                type="primary"
                size="mini"
                class="yili-button-primary"
                @click="isAddCustomersDialog = true"
                >添加客户</el-button
              >
            </div>
            <dyTable
              ref="customer"
              :tableData="ruleForm.customerList"
              border
              :formData="detailData"
              :tableApi="projectCustomerPageApi"
              tableType="customer"
              style="width: 100%"
            >
              <el-table-column prop="index" label="序号" width="52">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column property="account" label="登录账号">
              </el-table-column>
              <el-table-column property="name" label="客户名称">
                <template slot-scope="scope">
                  {{ scope.row.name || "--" }}
                </template>
              </el-table-column>
              <el-table-column property="phone" label="手机号">
              </el-table-column>
              <el-table-column property="city" label="所在城市">
                <template slot-scope="scope">
                  {{ scope.row.city || "--" }}
                </template>
              </el-table-column>
              <el-table-column property="authState" label="认证状态">
                <template slot-scope="scope">
                  <el-tag
                    type="success"
                    v-if="scope.row.authState != '未认证'"
                    >{{ scope.row.authState }}</el-tag
                  >
                  <el-tag type="danger" v-else>{{
                    scope.row.authState
                  }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column property="businessManager" label="客户经理">
                <template slot-scope="scope">
                  {{ scope.row.businessManager || "--" }}
                </template>
              </el-table-column>
              <el-table-column property="createTime" label="创建时间">
              </el-table-column>
              <el-table-column
                prop="date"
                label="操作"
                fixed="right"
                width="136"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    style="color: #f64a2d"
                    @click="
                      handleDelPolicy(scope.$index, scope.row, 'customerList')
                    "
                    >移除</el-button
                  >
                </template>
              </el-table-column>
            </dyTable>
          </div>
        </AssociationScrollView> -->
        <AssociationScrollView title="项目保单" name="3">
          <div class="container container-box">
            <div>
              <el-button
                type="primary"
                icon="el-icon-refresh"
                size="small"
                plain
                @click="$refs.policy.refresh()"
              ></el-button>
              <el-button
                type="primary"
                size="mini"
                class="yili-button-primary"
                @click="isAssociationDialog = true"
                >绑定保单</el-button
              >
            </div>
            <dyTable :isTraditional="true"
              :formData="detailData" @changeList="e => ruleForm.policyInfoList = e"
              ref="policy"
              :tableApi="projectPolicyListPageApi"
              tableType="policy"
              :tableData="ruleForm.policyInfoList"
              border
              style="width: 100%"
            >
              <el-table-column prop="index" label="序号" width="52">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column prop="projectName" label="项目名称"></el-table-column>
              <el-table-column prop="policyNo" label="保单号" width="194">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="handleGoPath(scope.row)"
                    style="text-decoration: underline"
                    >{{ scope.row.policyNo }}</el-button
                  >
                </template>
              </el-table-column>
              <el-table-column prop="insuranceOfDate" label="投保日期"></el-table-column>
              <el-table-column prop="term" label="保障期限"></el-table-column>
              <el-table-column prop="state" label="保单状态" width="96">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.state == 2">保障中</el-tag>
                  <el-tag type="warning" v-else-if="scope.row.state == 1"
                    >未生效</el-tag
                  >
                  <el-tag type="info" v-else>已过期</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                prop="totalPremium"
                label="总保费(元)"
                width="108"
              >
              </el-table-column>
              <el-table-column prop="policyHolder" label="投保人" width="90">
                <template slot-scope="scope">
                  {{ scope.row.policyHolder || scope.row.policyHolderName }}
                </template>
              </el-table-column>
              <el-table-column prop="insured" label="被保险人" width="90">
                <template slot-scope="scope">
                  {{ scope.row.insured || scope.row.insuredName }}
                </template>
              </el-table-column>
              <el-table-column
                prop="insuredCategoryName"
                label="险种类型"
                width="112"
              >
                <template slot-scope="scope">
                  {{ scope.row.insuredCategoryName || '--' }}
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceCompanyName"
                label="保险公司"
                width="132"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.insuranceCompanyName ||
                    scope.row.primaryInsuranceCompanyName
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceBranchName"
                label="分支机构"
                width="118"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.insuranceBranchName ||
                    scope.row.primaryInsuranceBranchName
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="deviceTypeName"
                label="设备类型"
                width="90"
              >
              </el-table-column>
              <el-table-column prop="plate" label="车牌号" width="80">
                <template slot-scope="scope">
                  {{ scope.row.plate || '--' }}
                </template>
              </el-table-column>
              <el-table-column prop="frameNo" label="车架号" width="80">
                <template slot-scope="scope">
                  {{ scope.row.frameNo || '--' }}
                </template>
              </el-table-column>
              <el-table-column prop="salesmanName" label="业务员" width="80">
                <template slot-scope="scope">
                  {{ scope.row.salesmanName || '--' }}
                </template>
              </el-table-column>
              <el-table-column
                prop="salesmanDeptName"
                label="所属部门"
                width="156"
              >
              </el-table-column>
              <el-table-column prop="customerName" label="所属客户" width="80">
                <template slot-scope="scope">
                  {{ scope.row.customerName || '--' }}
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="录单时间" width="80">
                <template slot-scope="scope">
                  {{ scope.row.createTime || '--' }}
                </template>
              </el-table-column>
              <el-table-column
                prop="date"
                label="操作"
                fixed="right"
                width="110"
              >
                <template slot-scope="scope">
                  <el-button type="text" @click="handleGoPath(scope.row)"
                    >查看详情</el-button
                  >
                  <!-- <el-button
                    type="text"
                    style="color: #f64a2d"
                    @click="
                      handleDelPolicy(scope.$index, scope.row, 'policyInfoList')
                    "
                    >解绑</el-button
                  > -->
                </template>
              </el-table-column>
            </dyTable>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="机械设备" name="4">
          <div class="container container-box">
            <div>
              <el-button
                type="primary"
                icon="el-icon-refresh"
                size="small"
                plain
                @click="$refs.vehicle.refresh()"
              ></el-button>
              <el-button
                type="primary"
                size="mini"
                class="yili-button-primary"
                @click="isGroupDialog = true"
                >绑定设备</el-button
              >
            </div>
            <dyTable :isTraditional="true"
              :tableData="ruleForm.vehicleList"
              :formData="detailData"
              :tableApi="projectDevicePageApi" @changeList="e => ruleForm.vehicleList = e"
              tableType="vehicle"
              ref="vehicle"
              border
              style="width: 100%"
            >
              <el-table-column prop="index" label="序号" width="52">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column
                prop="deviceName"
                label="设备名称"
              ></el-table-column>
              <el-table-column
                prop="groupName"
                label="所属车组"
              ></el-table-column>
              <el-table-column prop="frameNo" label="车架号"></el-table-column>
              <el-table-column
                prop="categoryName"
                label="机械种类"
              ></el-table-column>
              <el-table-column
                prop="typeName"
                label="设备类型"
              ></el-table-column>
              <el-table-column
                prop="brandName"
                label="设备品牌"
              ></el-table-column>
              <el-table-column
                prop="terminalNo"
                label="终端号"
              ></el-table-column>
              <el-table-column prop="jsName" label="绑定机手"></el-table-column>
              <el-table-column
                prop="createTime"
                label="创建时间"
              ></el-table-column>
              <el-table-column
                prop="date"
                label="操作"
                fixed="right"
                width="136"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="handleGoPath(scope.row, '/SCDetails')"
                    >查看详情</el-button
                  >
                </template>
              </el-table-column>
            </dyTable>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="查看用户" name="5">
          <div class="container container-box">
            <div>
              <el-button
                type="primary"
                icon="el-icon-refresh"
                size="small"
                plain
                @click="$refs.bladeUser.refresh()"
              ></el-button>
              <el-button
                type="primary"
                size="mini"
                class="yili-button-primary"
                @click="isAddUserDialog = true"
                >添加用户</el-button
              >
            </div>
            <dyTable
              :tableData="ruleForm.bladeUserList"
              ref="bladeUser"
              :formData="detailData"
              :tableApi="projectViewUserPageApi"
              border
              style="width: 100%"
            >
              <el-table-column prop="index" label="序号" width="52">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column
                prop="tenantName"
                label="所属租户"
              ></el-table-column>
              <el-table-column property="account" label="登录账号">
              </el-table-column>
              <el-table-column property="nickname" label="昵称">
              </el-table-column>
              <el-table-column property="name" label="姓名"> </el-table-column>
              <el-table-column property="phone" label="手机号">
              </el-table-column>
              <el-table-column
                prop="date"
                label="操作"
                fixed="right"
                width="136"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    style="color: #f64a2d"
                    @click="
                      handleDelPolicy(scope.$index, scope.row, 'bladeUserList')
                    "
                    >移除</el-button
                  >
                </template>
              </el-table-column>
            </dyTable>
          </div>
        </AssociationScrollView>
      </AssociationScroll>
    </el-form>

    <addUserDialog
      v-model="isAddUserDialog"
      :defaultList="ruleForm.bladeUserList"
      @submit="handleAddUser"
    />
    <addCustomersDialog
      v-model="isAddCustomersDialog"
      :defaultList="ruleForm.customerList"
      @submit="handleAddCustomers"
    />
    <addPolicyDialog
      v-model="isAssociationDialog"
      :policyIds="ruleForm.policyProjectIds || []" :projectId="detailData.id || ''"
      @submit="handleAddPolicyProject"
    />
    <addGroupDialog
      v-model="isGroupDialog"
      :idsList="ruleForm.vehicleGroupIds || []" :projectId="detailData.id || ''" :nameList="ruleForm.vehicleGroupNames || []"
      @submit="handleAddGroup"
    />
  </div>
</template>

<script>
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import FormListUpload from "@/components/FormListUpload/index.vue";
import SelectPolicy from "@/views/routerProjectManagement/components/ProjectList/components/SelectPolicy.vue";
import addPolicyDialog from "@/views/routerProjectManagement/components/ProjectList/components/from/addPolicyDialog.vue";
import addUserDialog from "@/views/routerProjectManagement/components/ProjectList/components/from/addUserDialog.vue";
import addCustomersDialog from "@/views/routerProjectManagement/components/ProjectList/components/from/addCustomersDialog.vue";
import addGroupDialog from "@/views/routerProjectManagement/components/ProjectList/components/from/addGroupDialog.vue";
import dyTable from "@/views/routerProjectManagement/components/ProjectList/components/from/dyTable.vue";
import { inputNum } from "@/views/routerProjectManagement/components/ProjectList/components/utils.js";

import { isCardNo, phoneFun, uploadFile, fileToBase64 } from "@/utils/index";
import { dictionaryBatch } from "@/api/policy";
import {
  projectPolicyList,
  projectDevicePage,
  projectDeviceList,
  projectViewUserPage,
  projectCustomerPage,
  projectPolicyListPage,
  addProjectCustomer,
  bindPolicyProject,
  addViewUser,
  bindVehicleGroup,
  delProjectCustomer,
  delViewUser,
  projectFilePage,
  addProjectFile,
  delProjectFile,
} from "@/api/basicData";
import textSwitch from "@/components/textSwitch/index.vue";
import dayjs from "dayjs";
export default {
  props: {
    detailData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    detailData: {
      handler(v) {
        this.ruleForm = JSON.parse(JSON.stringify({ ...this.ruleForm, ...v }));
        if (this.detailData && this.detailData.id) {
          this.getprojectFile();
        }
        this.$forceUpdate();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    var validateMobile = (rule, value, callback) => {
      if (!phoneFun(value) && value) {
        callback(new Error("请输入密码"));
      } else {
        // this.$refs.ruleForm.validateField('checkPass');
        callback();
      }
    };
    return {
      trid: "",
      policyTableData: [],
      isAssociationDialog: false,
      isAddUserDialog: false,
      isAddCustomersDialog: false,
      isGroupDialog: false,
      CXchannel: [],
      riskTableData: [],
      isAddRiskDialog: false,
      riskEditData: {},
      projectRiskIds: [],
      ruleForm: {
        projectName: "",
        signingDate: "",
        remark: "",
        createTime: null,
        updateTime: null,
        createUser: null,
        createUserName: null,
        parentId: "",
        organPath: "",
        projectSequence: "",
        projectType: "",
        projectTypeValue: "",
        productType: "",
        productTypeValue: "",
        performanceType: "",
        performanceTypeName: "",
        targetMoney: "",
        targetCompensate: "",
        signPremiums: "",
        signCompensateRate: "",
        signCtrlRate: "",
        targetCtrlRate: "",
        signPremiumsDisplay: true,
        targetCtrlRateDisplay: true,
        signCtrlRateDisplay: true,
        targetCompensateDisplay: true,
        signCompensateRateDisplay: true,
        incomeMoney: "",
        incomeDisburse: "",
        compensateTargetRate: "",
        projectLeader: "",
        projectLeaderId: "",
        startDateTime: "",
        endDateTime: "",
        enable: true,
        order: 0,
        projectCategory: '',
        projectCategoryValue: '',
        affiliateUserId: "",
        affiliateUserName: "",
        fileList: [],
        policyInfoList: [],
        policyProjectIds: [],
        customerList: [],
        bladeUserList: [],
        vehicleList: [],
        vehicleGroupIds: [],
        vehicleGroupNames: [],
      },
      rules: {
        projectName: [
          { required: true, message: "请输入项目名称", trigger: "change" },
        ],
        projectCategory: [
          { required: true, message: "请选择项目类型", trigger: "change" },
        ],
        // startDateTime: [
        //   { required: true, message: "请选择开始时间", trigger: "change" },
        // ],
        // endDateTime: [
        //   { required: true, message: "请选择结束时间", trigger: "change" },
        // ],
        projectType: [
          { required: true, message: "请选择项目性质", trigger: "change" },
        ],
        // targetMoney: [
        //   { required: true, message: "请输入目标保费", trigger: "change" },
        // ],
        // targetCompensate: [
        //   { required: true, message: "请输入目标赔付率", trigger: "change" },
        // ],
        // projectLeaderId: [
        //   { required: true, message: "请选择项目负责人", trigger: "change" },
        // ],
        // enable: [{ required: true, message: "请选择状态", trigger: "change" }],
        // performanceTypeName: [
        //   { required: true, message: "请选择绩效类型", trigger: "change" },
        // ],
      },
      projectItems: [],
      projectLines: [],
      performanceType: [],
      projectCategoryItems: [
        {
          dictValue: '保险科技',
          dictKey: 1
        },
        {
          dictValue: '传统保险',
          dictKey: 2
        },
      ],
      projectViewUserPageApi: projectViewUserPage,
      projectCustomerPageApi: projectCustomerPage,
      projectPolicyListPageApi: projectPolicyListPage,
      projectDevicePageApi: projectDevicePage,
      fileloading: false,
    };
  },
  components: {
    AssociationScroll,
    AssociationScrollView,
    EmployeeSelect,
    FormListUpload,
    SelectPolicy,
    addPolicyDialog,
    textSwitch,
    dyTable,
    addUserDialog,
    addCustomersDialog,
    addGroupDialog,
  },
  created() {
    this.init();
  },
  computed: {},
  methods: {
    handleGoPath(e, path) {
      if (path) {
        sessionStorage.setItem(
          "vehicleIdList",
          JSON.stringify(this.ruleForm.vehicleList.map((val) => val.id))
        );
        this.$nextTick(() => {
          this.$router.push({
            path: path,
            query: {
              vehicleIndex: this.ruleForm.vehicleList.findIndex(
                (val) => val.id == e.id
              ),
            },
          });
        });
        return false;
      }
      this.$router.push({
        path:
          "/routerPolicyManagement/ConstructionMachinery/policyDetails/" +
          (e.policyId || e.id),
      });
    },

    init() {
      // 证件类型identCardType 保险公司（insuranceCompany）、设备品牌（vehicleBrand）、设备类型（vehicleType）主险种编码：primaryInsuranceType 附加险种编码：attachInsuranceType 投保门店applicantStore 投保方式applicantMode 投保类型applicantType 投保方式applicantMode 险种类型FCXInsCategory 职业身份JobCategory
      dictionaryBatch({
        codes: "riskCtrlType,projectLines",
      }).then((res) => {
        if (res && res.data) {
          const { riskCtrlType, projectLines } = res.data;
          this.projectItems = riskCtrlType || [];
          this.projectLines = projectLines || [];
          // this.performanceType = performanceType || [];
        }
      });
    },
    handleSubmit() {
      return new Promise((resolve, reject) => {
        this.$refs["ruleForm"].validate((valid) => {
          console.log(valid);
          if (valid) {
            let ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
            if (ruleForm.fileList && ruleForm.fileList.length) {
              ruleForm.fileList = ruleForm.fileList.map((val) => {
                return {
                  attName: val.fileName,
                  attType: val.fileType,
                  attSize: 999,
                  visitUrl: val.url,
                };
              });
            }
            // if (ruleForm.policyInfoList && ruleForm.policyInfoList.length) {
            //   ruleForm.policyProjectIds = ruleForm.policyInfoList.map((val) => val.id);
            // }
            if (ruleForm.customerList && ruleForm.customerList.length) {
              ruleForm.customerIds = ruleForm.customerList.map((val) => val.id);
            }
            if (ruleForm.bladeUserList && ruleForm.bladeUserList.length) {
              ruleForm.viewUserIds = ruleForm.bladeUserList.map(
                (val) => val.id
              );
            }
            // ruleForm.startDateTime = dayjs(ruleForm.startDateTime).format(
            //   "YYYY-MM-DD 00:00:00"
            // );
            // ruleForm.endDateTime = dayjs(ruleForm.endDateTime).format(
            //   "YYYY-MM-DD 23:59:59"
            // );
            // if (this.projectRiskIds && this.projectRiskIds.length) {
            //   ruleForm.projectRiskIds = this.projectRiskIds;
            // }
            // let findInd = ruleForm.targetInfo.findIndex(
            //   (val) =>
            //     val.year == "2024" &&
            //     (val.yearTargetMoney === "" ||
            //       val.yearTargetCompensate === "" ||
            //       val.yearTargetIncrease === "")
            // );
            // if (findInd > -1) {
            //   this.$message.error(
            //     `请填写项目目标${ruleForm.targetInfo[findInd].year}年度参数`
            //   );
            //   return false;
            // }
            ruleForm.bladeUserId = this.$store.state.userInfo.MJUserId;
            resolve(ruleForm);
          } else {
            reject("error submit!!");
            return false;
          }
        });
      });
    },
    input_num(e, tiem, key, max, decimal = 2) {
      inputNum(e, tiem, key, max, decimal);
    },
    handleChangeDetails(e) {
      this.isAssociationDialog = false;
      this.ruleForm.policyInfoList.push(...e);
    },
    handleDelPolicy(ind, tiem, key) {
      this.$confirm(
        `确认移除该${key == "customerList" ? "客户" : "用户"}?`,
        "解绑",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          if (this.detailData && this.detailData.id) {
            let delApi =
              key == "customerList" ? delProjectCustomer : delViewUser;
            console.log(tiem);
            delApi({
              projectId: this.detailData.id,
              dataId: tiem.id,
            })
              .then((res) => {
                this.$message({
                  type: "success",
                  message: "移除成功!",
                });
                this.$refs[
                  key == "customerList" ? "customer" : "bladeUser"
                ].refresh();
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "移除失败",
                });
              });
          } else {
            let index = this.ruleForm[key].findIndex(vv=> vv.id == tiem.id);
            this.ruleForm[key].splice(index, 1);
            this.$message({
              type: "success",
              message: "移除成功!",
            });
            this.$forceUpdate();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消移除",
          });
        });
    },
    async handleAddPolicyProject(e) {
      if (e) {
        if (!e.length) {
          this.ruleForm.policyProjectIds = [];
          this.ruleForm.policyProjectNames = [];
          this.ruleForm.policyInfoList = [];
          this.isAssociationDialog = false;
        }
        if (this.detailData && this.detailData.id) {
          let { data } = await bindPolicyProject({
            dataId: e,
            projectId: this.detailData.id,
          });
          // this.isAssociationDialog = false;
          // this.$refs.policy.refresh();
          // return false;
          this.$refs.policy.refresh();
        }else{
          let res = await projectPolicyList({ policyProjectId: e });
          this.ruleForm.policyInfoList = res.data;
        }
        this.isAssociationDialog = false;
        this.ruleForm.policyProjectIds = e.split(",");
        // this.$refs.policy.refresh();
      }else {
        if (this.detailData && this.detailData.id) {
          let { data } = await bindPolicyProject({
            projectId: this.detailData.id,
          });
        }
        this.ruleForm.policyProjectIds = [];
        this.ruleForm.policyProjectNames = [];
        this.ruleForm.policyInfoList = null;
        this.isAssociationDialog = false;
          this.$refs.policy.refresh();
      }
    },
    async handleAddGroup(e) {
      if (e && e.length) {
        if (this.detailData && this.detailData.id) {
          let { data } = await bindVehicleGroup({
            dataId: e.map((val) => val.id).toString(),
            projectId: this.detailData.id,
          });
          // this.isGroupDialog = false;
          // this.$refs.vehicle.refresh();
          // return false;
        };
        this.ruleForm.vehicleGroupIds = e.map((val) => val.id);
        this.ruleForm.vehicleGroupNames = e.map((val) => val.label);
        let { data } = await projectDeviceList({
          vehicleGroupIds: e.map((val) => val.id).toString(),
          projectId: this.detailData.id,
        });
        this.isGroupDialog = false;
        this.ruleForm.vehicleList = data;
      }else {
        if (this.detailData && this.detailData.id) {
          let { data } = await bindVehicleGroup({
            dataId: '',
            projectId: this.detailData.id,
          });
        }
        this.ruleForm.vehicleGroupIds = [];
        this.ruleForm.vehicleGroupNames = [];
        this.ruleForm.vehicleList = [];
        this.isGroupDialog = false;
        this.$refs.vehicle.refresh();
      }
    },
    async handleAddUser(e) {
      this.ruleForm.bladeUserList = e;
      this.isAddUserDialog = false;
      if (this.detailData && this.detailData.id) {
        let { data } = await addViewUser({
          dataId: e.map((val) => val.id).toString(),
          projectId: this.detailData.id,
        });
        this.$refs.bladeUser.refresh();
      }
    },
    async handleAddCustomers(e) {
      this.ruleForm.customerList = e;
      this.isAddCustomersDialog = false;
      if (this.detailData && this.detailData.id) {
        let { data } = await addProjectCustomer({
          dataId: e.map((val) => val.id).toString(),
          projectId: this.detailData.id,
        });
        this.$refs.customer.refresh();
      }
    },
    async getprojectFile(e) {
      this.fileloading = true;
      let { data, code } = await projectFilePage({
        index: 1,
        size: 999,
        projectId: this.detailData.id,
      });
      this.fileloading = false;
      if (code == 200 && data && data.records && data.records.length) {
        this.ruleForm.fileList = data.records.map((val) => {
          return {
            fileName: val.attName,
            fileType: val.attType,
            attSize: 999,
            oldFile: true,
            id: val.id,
            url: val.visitUrl,
          };
        });
        this.total = data.total || 0;
      } else {
        this.tableList = [];
      }
    },
    handleShowAddRisk() {
      this.isAddRiskDialog = true;
    },
    handleChangeSalesman(id, name) {
      this.ruleForm.projectLeader = name;
      // this.$refs.ruleForm.clearValidate("projectLeaderId");
      // this.$refs.ruleForm.validateField("projectLeaderId");
    },
    changeSelect(e, list, key, item) {
      let value = list.find((val) => val.dictValue == e);
      item[key] = value.dictKey;
    },
    handleReview(item) {
      this.trid = item.id;
    },
    handleAllFileSuccess(e) {
      if (!this.detailData || !this.detailData.id) {
        return false;
      }
      addProjectFile({
        projectId: this.detailData.id,
        loginUserId: this.$store.state.userInfo.MJUserId,
        fileList: this.ruleForm.fileList
          .filter((val) => !val.oldFile)
          .map((val) => {
            return {
              attName: val.fileName,
              attType: val.fileType,
              attSize: 999,
              visitUrl: val.url,
            };
          }),
      })
        .then((res) => {
          this.getprojectFile();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleDelItem(e) {
      if (e && e.id) {
        delProjectFile({
          projectId: this.detailData.id,
          dataId: e.id
        })
          .then((res) => {
            this.$message.success('删除成功')
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
}
.container-box {
  padding-top: 18px;
  border-top: 2px dashed #dedede;
  .personnel_repetition {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333333;
    padding: 8px 12px;
    margin-bottom: 24px;
    background: #cce6ff;
    border-radius: 8px;
    &_details {
      color: #0080ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .yili-button-primary {
    background: #4278c9;
    border: 1px solid #4278c9;
    margin-bottom: 16px;
  }
  &-form {
    &-label {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.el-collapse-box {
  height: 100%;
  .view-title {
    flex: 1;
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      height: 48px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      img {
        width: 6px;
        height: 24px;
        margin-right: 8px;
      }
    }
    &-right {
      display: flex;
      align-items: center;
    }
    &-float {
      position: fixed;
      background-color: #ffffff;
      border-bottom: none;
      border-radius: 8px 8px 0 0;
      padding: 12px 24px 0;
      margin: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      z-index: 3;
    }
  }
  .el-collapse {
    border: none;
    .el-collapse-item {
      margin-bottom: 10px;
      padding: 24px;
      border-radius: 8px;
      border: none;
      background-color: #fff;
      overflow: hidden;
      ::v-deep .el-collapse-item__arrow {
        display: none;
      }
    }
  }
  .merge-box {
    display: flex;
    align-items: center;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    .merge-date {
      ::v-deep .el-input__inner {
        padding: 0;
        text-align: center;
        border: none;
      }
      ::v-deep .el-input__prefix {
        display: none;
      }
    }
  }
  .merge-box {
    display: flex;
    align-items: center;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 3px 10px;
    > span {
      display: block;
      text-align: center;
      width: 20px;
    }
    .merge-date {
      flex: 1;
      text-align: center;
      ::v-deep .el-input__inner {
        padding: 0;
        text-align: center;
        border: none;
      }
      ::v-deep .el-input__prefix {
        display: none;
      }
    }
  }
  .el-select {
    width: 100%;
  }
  .el-autocomplete {
    width: 100%;
  }
  ::v-deep .el-descriptions-item__cell {
    padding-bottom: 20px;
  }
  ::v-deep .el-form-item {
    width: 30%;
    .el-form-item__content {
      width: 70%;
    }
    .el-form-item__label {
      width: 70%;
    }
  }
  @media (max-width: 1500px) {
    ::v-deep .el-form-item {
      width: 45%;
      .el-form-item__content {
        width: 85%;
      }
      .el-form-item__label {
        width: 85%;
        display: flex;
        align-items: center;
      }
    }
  }
  @media (max-width: 1000px) {
    ::v-deep .el-form-item {
      width: 100%;
      .el-form-item__content {
        width: 90%;
      }
      .el-form-item__label {
        width: 90%;
        display: flex;
        align-items: center;
      }
    }
  }
  .plan-table {
    display: column;
    ::v-deep .el-form-item {
      width: 100%;
      .el-form-item__content {
        width: 100%;
      }
      .el-form-item__label {
        width: 100%;
        display: flex;
        align-items: center;
      }
      .el-icon--right {
        margin-left: 0;
      }
    }
  }
  ::v-deep .el-date-editor {
    width: 100%;
  }
  .input-customization {
    ::v-deep .el-input__inner {
      padding-right: 72px;
    }
  }
  .certificate-label {
    ::v-deep .el-form-item__label {
      width: 70%;
    }
    .certificate-box {
      display: flex;
      justify-content: space-between;
      &-right {
        display: flex;
        align-items: center;
        .example {
          font-size: 12px;
          color: #0080ff;
          margin-right: 8px;
          cursor: pointer;
        }
        .label-button {
          height: 24px;
          line-height: 24px;
          font-size: 14px;
          padding: 0 6px;
          color: #ffaa00;
          background: rgba(255, 170, 0, 0.16);
          border-radius: 4px;
          border: 1px solid #ffaa00;
          cursor: pointer;
        }
      }
    }
  }
  .insurance-company {
    display: flex;
    justify-content: flex-end;
    max-width: 1000px;
    font-weight: bold;
    color: #4278c9;
    font-size: 16px;
    margin-top: 16px;
  }
  .insurance-clause {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 1000px;
    color: #00bc0d;
    font-size: 14px;
    border-top: none;
    border: 1px solid #ebeef5;
    cursor: pointer;
  }
  .input-position {
    .input-suffix {
      position: relative;
      width: 44px;
      height: 100%;
      border-left: 1px solid #dcdfe6;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        transform: translate(-50%, -50%);
      }
    }
    ::v-deep .el-input__inner {
      padding-right: 48px;
    }
  }
}
.clause-title {
  margin: 16px 0;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.list-item {
  &:first-child {
    border: none;
    padding-top: 0;
  }
  padding-top: 10px;
  border-top: 1px dashed #999;

  position: relative;
  .del-button {
    position: absolute;
    right: 0;
  }
}
.el-button--primary {
  background-color: #4278c9;
  border-color: #4278c9;
}
.el-button--primary.is-plain {
  color: #4278c9;
  background: rgba(66, 120, 201, 0.1);
  border-color: #4278c9;
}
</style>
<style lang="scss">
.policy-label-style {
  font-size: 14px;
  color: #333333;
  margin-bottom: 12px;
}
.distpicker-address-wrapper select {
  width: 100%;
  border-radius: 4px;
  padding: 0;
  height: 32px;
  font-size: 13px;
  min-width: 80px;
}
.distpicker-all-box select {
  width: 30%;
}
.hide-picker label:nth-of-type(1) {
  display: none;
}
.popper-customization {
  background: rgba(255, 170, 0, 0.16) !important;
  color: #ffaa00 !important;
  border: 1px solid #ffaa00 !important;
  // .popper__arrow::after {
  //   border-top-color: #747574;
  // }
}
.popper-customization[x-placement^="top"] .popper__arrow::after {
  border-top-color: #ffaa00 !important;
}
.popper-customization[x-placement^="top"] .popper__arrow {
  border-top-color: #ffaa00 !important;
}
.el-collapse-box {
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    // overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__body {
    tr {
      position: relative;
    }
    // .table-del {
    //   position: absolute;
    //   top: 50%;
    //   right: -50px;
    //   transform: translateY(-50%);
    //   font-size: 30px;
    //   color: #c94242;
    //   cursor: pointer;
    // }
    .el-form-item {
      margin: 0;
    }
  }
  .plan-table {
    .has-gutter,
    .el-table__fixed-header-wrapper {
      tr {
        overflow: hidden;
      }
    }
    .el-table__body-wrapper {
      overflow: visible;
    }
  }
}
</style>
